////////
// THIS IS THE FILE BEING IMPORTED TO THE App.js FILE FOR DISPLAY.
////////
import React from "react";
import { Nav, NavLink, NavMenu }
    from "./NavbarElements";
import './index.css'
import Logo from'./logo.png';

const navbarFont = {
    fontFamily: "Times-Italic", 
  };

////////
// THIS IS THE FILE BEING IMPORTED TO THE App.js FILE FOR DISPLAY.
////////



const Navbar = () => {
    return (
        <>
            <Nav>
                <NavMenu>
                    <div>
                    <img  src={Logo} height='60px' alt="fireSpot" />
                    </div>
                    <div class="dropdown">
                        <button class="dropbtn" style={navbarFont} activeStyle>Services
                            <i class="fa fa-caret-down"></i>
                        </button>
                        <div class="dropdown-content">
                            <NavLink to="/roofing" style={navbarFont} activeStyle>
                                Roofing 
                            </NavLink>
                            <NavLink to="/exterior" style={navbarFont} activeStyle>
                                 Exterior 
                            </NavLink>
                            <NavLink to="/interior" style={navbarFont} activeStyle>
                                Interior 
                            </NavLink>
                        </div>
                    </div>
                    <div class="dropdown">
                        <button class="dropbtn" style={navbarFont} activeStyle>About
                            <i class="fa fa-caret-down"></i>
                        </button>
                        <div class="dropdown-content">
                            <NavLink to="/about" style={navbarFont} activeStyle>
                                About Us
                            </NavLink>
                            <NavLink to="/contact" style={navbarFont} activeStyle>
                                Contact
                            </NavLink>
                            <NavLink to="/team" style={navbarFont} activeStyle>
                                Team
                            </NavLink>
                            <NavLink to="/portfolio" style={navbarFont} activeStyle>
                                Portfolio
                            </NavLink>
                        </div>
                    </div>
                </NavMenu>
            </Nav>
        </>
    );
};
 
export default Navbar;

