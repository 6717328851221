import React from "react";
import './team.css'
import Jack from'./Jack.png';
import Jacob from'./Jacob.png';
import RJ from'./RJ.png';
import Brian from'./Brian.png';

const navbarFont = {
  fontFamily: "Times-Italic", // Replace with any system font you prefer
};

const Team = () => {
	return (
		<div className = 'Team-header' style={navbarFont}>
			<h1>
          Team
			</h1>
		  <p className = 'Team'>       
		  <img  src={Jacob} alt="fireSpot" height='200px'/>   
          <h3>Jacob Spearman | Founder</h3>
          <p>
          Jacob is a successful founder and entrepreneur with over 10 years of experience in construction. A graduate of Washington & Jefferson College (B.A.) and Muskingum University (M.A.), Jacob won NCAA All-American honors as a captain of the Washington & Jefferson Wrestling Team. Jacob is passionate about hard work and delivering excellent customer service and helping clients achieve their dreams. In his free time, Jacob loves spending time in the mountains with his girlfriend, dog, and cats.
          </p>
          </p>
          <p className = 'Team'> 
		  <img  src={RJ} alt="fireSpot" height='200px'/>
          <h3>Robert Funk | Founder </h3>  
          <p>
          A focused entrepreneur with over 12 years of experience in the construction industry, Robert loves helping clients. A meticulous problem solver, Robert handles projects with an emphasis on efficiency, clear coordination, and excellence in execution. In his free time, RJ loves spending time with his three kids, working out, and housing project.
          </p>
          </p>
          <p className = 'Team'> 
		  <img  src={Jack} alt="fireSpot" height='200px'/>
          <h3>Jack Barnhart-Sullivan | Founder</h3>
          <p>
          An experienced and knowledgeable construction contractor, Jack builds business through exceptional customer service. Named after Jack Nicklaus, Jack enjoys golfing in his free time, as well as snowboarding and watching the Yankees.
          </p>
          </p>

          <p className = 'Team'> 
		  <img  src={Brian} alt="fireSpot" height='200px'/>
          <h3>Brian Haney | Operations Specialist</h3>
          <p>
          Brian is an entrepreneur and operations specialist, focusing on optimizing contract flow and efficiency for MTPD. A graduate of Washington & Jefferson College and Notre Dame Law School, Brian is passionate about customer service and excelling in work product delivery. In his free time, Brian enjoys weightlifting, boxing, and being with his family.
          </p>
          </p>

        <div>
				_____________________
			  </div>
        <div class="footer" style={navbarFont}>
				<p>
					<div>Call us at: (434)-907-3526 or (434)-907-3476</div>
					<div>Mountain Town Property Development</div>
					<div>____________________</div>
				</p>
			</div>
		</div>
	);
};

export default Team;
