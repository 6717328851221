import React from "react";
import './interior.css';
import kitchen from'./kitchen.png';
import cabinets from'./cabinets.png';
import basement from'./basement.png';
import floor from'./floor.png';


const navbarFont = {
    fontFamily: "Times-Italic", // Replace with any system font you prefer
  };

const Interior = () => {


	return (
		<div className = 'Interior-header' style={navbarFont}>
			<h1>Interior Services</h1>

			<p className = 'Interior'>
			We offer general contractor services for interior property development with a meticulous focus on excellence and customer service. We offer a host of interior property development services, including: flooring, cabinets, stairs, shelving, drywall, trim, painting, lights, appliance installation, and more.
			</p>

			<h3>
				Interior Services
			</h3>
			<p className = 'Interior'>
				Whether you desire the timeless elegance of hardwood, the versatility of laminate, the durability of tile, or the warmth of carpet, our wide selection of flooring options will cater to your style and budget, creating a foundation that showcases your personal taste and enhances the ambiance of any room.
			</p>
			<img  src={floor} height='300px' alt="fireSpot"/>
			<p className = 'Interior'>
			_____________________
			</p>
			<h3>
				Kitchens
			</h3>

			<p className = 'Interior'>
				From custom cabinetry and stylish countertops to innovative layouts and modern appliances, our talented team will transform your kitchen into a functional and beautiful space that perfectly suits your taste and lifestyle. Discover the joy of cooking and entertaining in a stunning kitchen tailored to your unique vision.			
			</p>

			<img  src={kitchen} height='300px' alt="fireSpot"/> 

			<p className = 'Interior'>
			_____________________
			</p>



			<h3>
				Cabinets
				</h3>


				<p className = 'Interior'>
			From sleek and modern designs to timeless and traditional styles, our high-quality cabinets are crafted with precision and attention to detail, providing ample storage space and enhancing the overall aesthetic of your kitchen, bathroom, or any other area of your home. Discover the transformative power of our exceptional cabinetry options and bring elegance and organization to your living spaces.
				</p>

			<img  src={cabinets} height='300px' alt="fireSpot"/> 
			
			<p className = 'Interior'>
			_____________________
				</p>


			<h3>
				Basements
				</h3>

			
				<p className = 'Interior'>
			Unlock the full potential of your basement with our professional basement finishing services at MTPD. From transforming underutilized spaces into stylish entertainment areas to creating functional home offices or additional living quarters, our expert team will work closely with you to design and execute a basement renovation that perfectly suits your needs and elevates the value of your home.
				</p>
			
			<img  src={basement} height='300px' alt="fireSpot"/> 

			<p className = 'Interior'>
			_____________________
			</p>
			<div class="footer" style={navbarFont}>
				<p>
					<div>Call us at: (434)-907-3526 or (434)-907-3476</div>
					<div>Mountain Town Property Development</div>
					<div>____________________</div>
				</p>
			</div>

	
			</div>


	);
};

export default Interior;