import React from "react";
import './about.css'


const navbarFont = {
    fontFamily: "Times-Italic", // Replace with any system font you prefer
  };

const About = () => {
	return (
		<div className = 'About-header' style={navbarFont} >
			<h1 style={navbarFont} >About Us</h1>
			<p className = 'About'  >
			Mountain Town Property Development, LLC (MTPD) is a general contractor and construction company located in Lynchburg, VA. We believe business is a process of service with an emphasis on hardwork, honesty, and integrity.
			Specializing in roofing, interior, and exterior renovations, we proudly service the community of Lynchburg, VA and surrounding areas. 
			Priding ourselves on our high quality work and seamless communication, we utilize only the best building practices and place customer satisfaction as our top priority. 
			</p>
			<p className = 'About'>
			Many home projects may seem daunting, but with our coordination and timely execution we aim to provide a stress-free experience for all of our customers. 
			Give us a call today and let the experts show you what we are capable of!
			</p>


			
			<div>
				_____________________
			</div>
			<div class="footer" style={navbarFont}>
				<p>
					<div>Call us at: (434)-907-3526 or (434)-907-3476</div>
					<div>Mountain Town Property Development</div>
					<div>____________________</div>
				</p>
	
			</div>
		</div>

	);
};

export default About;
