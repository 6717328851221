import React from "react";
import './contact.css'

const navbarFont = {
    fontFamily: "Times-Italic", // Replace with any system font you prefer
  };

const Contact = () => {
	return (
		<div className = 'Contact-header' style={navbarFont}>
		<h1 style={navbarFont}>
			Contact
		</h1>
		<div className='Contact-content'>
		<p className = 'Contact'>
          <div>
          Call us at: (434)-907-3526 or (434)-907-3476
          </div>
          <div>
          Email us at: mtpdllc@gmail.com
          </div>
          <div>
		Twitter @ <a href="https://twitter.com/MTPDLLC"> MTPDLLC</a>
	  </div>
	  <div>
		nextdoor @ <a href="https://nextdoor.com/pages/mountain-town-property-development-lynchburg-va/"> Mountain Town Property Development</a>
	  </div>

	
		<p className='Contact-content' text-align ='center'>
			_____________________
		</p>
		</p>
		</div>




		<div class="footer" style={navbarFont}>
				<p>
					<div>Call us at: (434)-907-3526 or (434)-907-3476</div>
					<div>Mountain Town Property Development</div>
					<div>____________________</div>
				</p>
			</div>

		</div>

	);
};

export default Contact;
