import React from "react";
import './roofing.css';
import gutters from './nueva.png'
import roof from'./Dos.png';

const navbarFont = {
    fontFamily: "Times-Italic", // Replace with any system font you prefer
  };
  
const Roofing = () => {
	return (
		<div className = 'Roofing-header' style={navbarFont}>

			<h1 style={navbarFont}>Roofing Services</h1>
			<p className = 'Roofing'>
			At MTPD, we offer comprehensive roofing and gutter services to protect and enhance your property, providing reliable solutions and exceptional craftsmanship for all your roofing and gutter needs.
		    We are dedicated to providing comprehensive roofing and gutter services that go above and beyond to safeguard and enhance your property. 
			With our commitment to reliability, we offer dependable solutions and employ exceptional craftsmanship to meet all your roofing and gutter requirements. Trust us to protect your home with our expertise and deliver unparalleled results that exceed your expectations.
			</p>

			<h3 >Roofing</h3>
			<p className = 'Roofing'>
			With years of industry experience and a proven track record, MTPD stands as a trusted leader in the roofing industry. Our skilled team of roofing experts is dedicated to delivering unparalleled craftsmanship and reliable solutions for all your roofing needs. Whether you require shingles for a traditional look, metal roofing for added durability, or rubber roofing for enhanced protection, we have the expertise to handle any roofing project with precision and expertise. From installation to repair and maintenance, we take pride in our attention to detail and commitment to customer satisfaction. 
			</p>
			<p className = 'Roofing'>
			<img  src={roof} height='300px' alt="fireSpot"/> 
			</p> 

			<p className = 'Roofing'>
				Our roofing services extend beyond the roof itself to encompass soffit and fascia, ensuring a complete and cohesive solution for your property's exterior. With meticulous attention to detail, our team will expertly install and repair soffit and fascia, enhancing both the aesthetics and functionality of your roofing system.
				When you choose MTPD, you can trust that your roofing project will be executed with the highest level of professionalism and expertise, ensuring a long-lasting and visually stunning result.
			</p>
			<p>
			_____________________
			</p>
			<h3 >Gutters</h3>
			<p className = 'Roofing'>
			We go beyond roofs and siding to provide comprehensive gutter services that keep your property safe and well-maintained. From gutter installation and repair to gutter cleaning and maintenance, our skilled team ensures proper water drainage, preventing potential damage to your home or building. Trust us to deliver efficient and reliable gutter solutions that safeguard your property for years to come.
			</p>
			<p className = 'Exterior'>
			<img  src={gutters} height='300px' alt="fireSpot"/> 
			</p> 

			<p >
			_____________________
			</p>
			<div class="footer" style={navbarFont}>
				<p>
					<div>Call us at: (434)-907-3526 or (434)-907-3476</div>
					<div>Mountain Town Property Development</div>
					<div>____________________</div>
				</p>
			</div>

		</div>

			
	);
};

export default Roofing;
