import React from 'react';
import './index.css'
import Logo from'./logo.png';
import Uno from'./Uno.png';
import Ocho from'./Ocho.png';

const Home = () => {

	return (
		<div className = 'Home-header'  text-align='center'>
			<p>
			<img rel="icon" href="favicon.ico" src={Logo} height = '230px' alt="fireSpot"/>
			</p>

			<p className = 'Home'>
			Mountain Town Property Development, LLC (MTPD) is a general contractor and construction company located in Lynchburg, VA. We believe business is a process of service with an emphasis on hardwork, honesty, and integrity.
			Specializing in roofing, interior, and exterior renovations, we service the community of Lynchburg, VA and surrounding areas. 
			Priding ourselves on our high quality work and seamless communication, we utilize only the best building practices and place customer satisfaction as our top priority. 
			</p>
			<div>
				____________
			</div>


            <div style={{ position: 'relative' }}>
				<div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}></div>
                <img src={Ocho} height='350px' alt="fireSpot" />
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    color: '#fff'
                }}>	
                    <h2>Give us a call!</h2>
                    <a href="/contact">
                        <button style={{ marginTop: '10px' }} id='Button10'>Call Now</button>
                    </a>
                </div>
            </div>


			<div>
				____________
			</div>


			<div style={{ position: 'relative' }}>
				<div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}></div>
                <img src={Uno} height='350px' alt="fireSpot" />
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    color: '#fff'
                }}>
					<h2>Services</h2>
					<p>
					<a href="https://www.mtpdllc.com/roofing">
					<button id='Button10'>Roofing</button>
					</a>
					</p>
					<p>
					<a href="https://www.mtpdllc.com/exterior">
					<button id='Button10'>Exterior</button>
					</a>
					</p>
					<div>
					<a href="https://www.mtpdllc.com/interior">
					<button align='center' id='Button10'>Interior</button>
					</a>
					</div>

                </div>
            </div>
			<div>
				____________
			</div>


			<h2>
			Frequently Asked Questions
			</h2>

				<div  align='center' >
				<h4>Are you insured and licensed?</h4>
				<p>Yes. We are insured and licensed.</p>
				</div>

				<div  align='center' >
				<h4>What areas do you serve?</h4>
				<p>We serve Lynchburg, VA and surrounding areas. Please contact us for specific location availablity.</p>
				</div>

				<div  align='center' >
				<h4>Do you have reviews?</h4>
				<p>Yes! Check out our reviews on our Google Page.</p>
				<a href="https://www.google.com/search?sca_esv=557478435&hl=en&authuser=3&q=Mountain+Town+Property+Development+LLC&stick=H4sIAAAAAAAAAONgU1I1qLBINEy2SDVMSjE2M0hOsjC3MqhISjNJsUhLTTMwSUs2MDdIXsSq5ptfmleSmJmnEJJfnqcQUJRfkFpUUqngklqWmpNfkJuaV6Lg4-MMACW_NidSAAAA&mat=IgIIAQ&sa=X&ved=2ahUKEwjTv6mau-GAAxVrk4kEHc4ECMoQ-rELegQIIRAE&biw=1212&bih=719&dpr=2">
				<button align='center' id='Button1'>Reviews</button>
				</a>
				</div>

				<div  align='center' >
				<h4>How much experience do you have?</h4>
				<p>Our team has over 20 years of combined consturction and contracting experience.</p>
				<a href="https://www.mtpdllc.com/team">
				<button align='center' id='Button1'>Team</button>
				</a>
				</div>
				<div>
					____________
				</div>

			<div class="footer">
				<p>
					<div>Call us at: (434)-907-3526 or (434)-907-3476</div>
					<div>Mountain Town Property Development</div>
					<div>____________________</div>
				</p>
			</div>

		</div>



		
	);
};

export default Home;

