import React from "react";
import './portfolio.css'
/////////////////////////
import Uno from'./Uno.png';

import Tres from'./Tres.png';
import Cuatro from'./Cuatro.png';
import Cinco from'./Cinco.png';
import Ocho from'./Ocho.png';


const navbarFont = {
    fontFamily: "Times-Italic", // Replace with any system font you prefer
  };

const Portfolio = () => {
	return (
		<body>


		<div className = 'Portfolio-header'>
			<h1 style={navbarFont}>
                Portfolio
			</h1>
		    <p>
                Our portfolio includes select examples of our work.
			</p>
			<div>
			<img  src={Uno} height = '400px' alt="fireSpot"/> 
			</div>
			<div>
				____________
			</div>
			<div>
			<img  src={Tres} height = '400px' alt="fireSpot"/> 
			</div>
			<div>
				____________
			</div>
			<div>
			<img  src={Cuatro} height = '400px' alt="fireSpot"/> 
			</div>
			<div>
				____________
			</div>
			<div>
			<img  src={Ocho} height = '400px' alt="fireSpot"/> 
			</div>
			<div>
				____________
			</div>
			<div>
			<img  src={Cinco} height = '400px' alt="fireSpot"/> 
			</div>
			<div>
				____________
			</div>
			<div class="footer" style={navbarFont}>
				<p>
					<div>Call us at: (434)-907-3526 or (434)-907-3476</div>
					<div>Mountain Town Property Development</div>
					<div>____________________</div>
				</p>
			</div>
		</div>
		</body>
	);
};

export default Portfolio;
