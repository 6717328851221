import React from "react";
import './exterior.css'
import fence from './seis.png'
import deck from './siete.png'
import siding from './diez.png'

const navbarFont = {
    fontFamily: "Times-Italic", // Replace with any system font you prefer
  };

const Exterior = () => {

	return (
		<div className = 'Exterior-header' style={navbarFont}>
			
			<h1 style={navbarFont}>
				Exterior Services
			</h1>
			
	
			<p className = 'Exterior'>
			We offer general contractor services for exterior property development with a meticulous focus on excellence and customer service. Whatever your goals, customer service and satisfaction is our top priority. We are experts in exterior property development services, including: siding (vinyl, hardie, shake), decks (wood and composite), fences (wood and vinyl), exterior trim, windows, doors, and painting.
			</p>

			<p className = 'Exterior'>
			</p>

			<h3>
				Siding
			</h3>


			<p className = 'Exterior'>
			From vinyl to Hardie siding, and even shake siding options, we offer a wide range of high-quality materials to transform and protect your home. Our experienced team is dedicated to delivering impeccable craftsmanship and tailored solutions that enhance the beauty and durability of your property. Explore our siding options today and let us bring your vision to life.
			</p>


			<div className = 'Exterior'>
			</div>
			<p className = 'Exterior'>
			<img  src={siding} height='300px' alt="fireSpot"/> 
			</p> 
			<p>
				_____________________
			</p>
			
			<h3>
				Fences
			</h3>

			
			<p className = 'Exterior'>
			Transform your property with our professional fence services, designed to enhance privacy, security, and curb appeal. From sturdy and stylish wood fences to low-maintenance vinyl or ornamental metal options, our skilled team will deliver exceptional craftsmanship and durable solutions that perfectly complement your property's aesthetics while meeting your specific needs. 			
			</p>

			<p className = 'Exterior'>
			<img  src={fence} height='300px' alt="fireSpot"/> 
			</p> 
			<p>
				_____________________
			</p>
			
			<h3>Decks</h3>

	
			<p className = 'Exterior'>
			Enhance your outdoor living space with our professional deck and patio building services at MTPD. Our skilled team will help you create a stunning and functional outdoor oasis, tailored to your unique preferences, allowing you to enjoy the beauty of nature and create lasting memories with family and friends.			
			</p>

			<p className = 'Exterior'>
			<img  src={deck} height='300px' alt="fireSpot"/> 
			</p>
			<p>
				_____________________
			</p>

			<div class="footer" style={navbarFont}>
				<p>
					<div>Call us at: (434)-907-3526 or (434)-907-3476</div>
					<div>Mountain Town Property Development</div>
					<div>____________________</div>
				</p>
			</div>

		</div>




			
	);
};

export default Exterior;
