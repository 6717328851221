import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background: black;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
  max-height: 100vh;
  max-width: 100vw;
  margin: 0;
  overflow-x:hidden;

`;

export const NavLink = styled(Link)`
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  font-size: 30px;
  height: 100%;
  cursor: pointer;
  max-height: 100vh;
  max-width: 100vw;


  &.active {
    font-weight: bold;
  }

`;

export const Bars = styled(FaBars)`
  display: none;
  color: black;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
    max-height: 100vh;
    max-width: 100vw;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  max-height: 100vh;
  max-width: 100vw;


  /* Services dropdown */
  .services-dropdown {
    display: flex;
    flex-direction: column;
    background: black;
    padding: 1rem 0;
    position: absolute;
    top: 105px;
    right: 0;
    z-index: 10;
  }
`;
