import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import Home from './pages';
import About from './pages/about';
import Team from './pages/team';
import Exterior from './pages/exterior';
import Interior from './pages/interior';
import Contact from './pages/contact';
import Portfolio from './pages/portfolio';
import Roofing from './pages/roofing';

function App() {
	return (
		<Router>
			<Navbar />
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route path='/about' element={<About />} />
				<Route path='/contact' element={<Contact />} />
				<Route path='/team' element={<Team />} />
				<Route path='/exterior' element={<Exterior />} />
				<Route path='/interior' element={<Interior />} />
				<Route path='/roofing' element={<Roofing />} />
				<Route path='/portfolio' element={<Portfolio />} />
			</Routes>
		</Router>
	);
}

export default App;


